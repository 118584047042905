import React from "react";

export const NeutralIcon = () => {
  return (
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 0.34375C8.33203 0.34375 0.09375 8.58203 0.09375 18.75C0.09375 28.918 8.33203 37.1562 18.5 37.1562C28.668 37.1562 36.9062 28.918 36.9062 18.75C36.9062 8.58203 28.668 0.34375 18.5 0.34375ZM18.5 34.7812C9.59375 34.7812 2.46875 27.6562 2.46875 18.75C2.46875 9.91797 9.59375 2.71875 18.5 2.71875C27.332 2.71875 34.5312 9.91797 34.5312 18.75C34.5312 27.6562 27.332 34.7812 18.5 34.7812ZM12.5625 17.5625C13.8242 17.5625 14.9375 16.5234 14.9375 15.1875C14.9375 13.9258 13.8242 12.8125 12.5625 12.8125C11.2266 12.8125 10.1875 13.9258 10.1875 15.1875C10.1875 16.5234 11.2266 17.5625 12.5625 17.5625ZM24.4375 12.8125C23.1016 12.8125 22.0625 13.9258 22.0625 15.1875C22.0625 16.5234 23.1016 17.5625 24.4375 17.5625C25.6992 17.5625 26.8125 16.5234 26.8125 15.1875C26.8125 13.9258 25.6992 12.8125 24.4375 12.8125ZM25.625 24.6875H11.375C10.707 24.6875 10.1875 25.2812 10.1875 25.875C10.1875 26.543 10.707 27.0625 11.375 27.0625H25.625C26.2188 27.0625 26.8125 26.543 26.8125 25.875C26.8125 25.2812 26.2188 24.6875 25.625 24.6875Z"
        fill="#4E667D"
      />
    </svg>
  );
};
