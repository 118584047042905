import React, { useEffect, useState } from "react";
import { navigate, Link } from "gatsby";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { useRealmApp } from "./realm_app";
import { SmileIcon, FrownIcon, LaughIcon, NeutralIcon } from "./icons";
import { getRelationTitle } from "../utils";
import moment from "moment";

const Notification = ({
  header_text,
  body,
  button_text,
  should_display_func,
  button_action,
}) => {
  const buttonStyle = button_text.includes("Contact")
    ? "deepen-blue-button"
    : "light-blue-button";
  const button = button_text ? (
    <Button className={buttonStyle} onClick={button_action}>
      {button_text}
    </Button>
  ) : null;

  return (
    <Alert variant="light" className="light-card" show={should_display_func()}>
      <Alert.Heading className="alert-heading mb-2 mt-3">
        {header_text}
      </Alert.Heading>
      {body}
      <div className="d-flex justify-content-center mb-3">{button}</div>
    </Alert>
  );
};

export const SetUpMeetingTimeNotification = ({ onActivate = () => {} }) => {
  const header = `It's time to meet your ${getRelationTitle("mentee")}!`;
  const body = (
    <h6 className="mb-2">Please contact them to schedule your first meeting</h6>
  );
  const button = `Contact ${getRelationTitle("mentee", true)}`;

  const buttonAction = () => {
    navigate("/member/dashboard/team");
  };

  const realmApp = useRealmApp();

  const shouldDisplayFunc = () => {
    const hasMentee = Boolean(realmApp.app.currentUser?.customData.mentee);
    const hasTime = realmApp.app.currentUser?.customData.menteeMeetingTime;
    return hasMentee && !hasTime;
  };

  if (shouldDisplayFunc()) {
    onActivate();
    return (
      <Notification
        header_text={header}
        body={body}
        button_text={button}
        should_display_func={shouldDisplayFunc}
        button_action={buttonAction}
      />
    );
  } else {
    return <></>;
  }
};

export const HowWasMeetingNotification = ({
  onActivate = () => {},
  with_mentor,
}) => {
  const realmApp = useRealmApp();
  const [notif, setNotif] = useState(<></>);

  async function maybeActivateNotification() {
    const user = realmApp.app.currentUser;

    // logged in?
    if (!user) {
      return;
    }

    // has partner?
    const hasPartner = with_mentor
      ? user.customData.mentor
      : user.customData.mentee;
    if (!hasPartner) {
      return;
    }

    // has meeting time with that partner?
    let team = await realmApp.fetchMentorMentee({
      menteeMeetingTime: 1,
      username: 1,
    });
    let [mentor_first_meet_time, mentor_meet_frequency] = team.get("mentor")
      ? [
          team.get("mentor").menteeMeetingTime?.first_timestamp,
          team.get("mentor").menteeMeetingTime?.frequency,
        ]
      : [null, null];

    const [firstMeetingTime, frequency] = with_mentor
      ? [mentor_first_meet_time, mentor_meet_frequency]
      : [
          Number(
            user.customData.menteeMeetingTime?.first_timestamp["$numberLong"]
          ),
          user.customData.menteeMeetingTime?.frequency,
        ];

    if (!firstMeetingTime) {
      return;
    }

    // is first meeting time in the future?
    let lastMeetingTime = firstMeetingTime;
    const now = new Date().getTime();
    if (lastMeetingTime > now) {
      return;
    }

    // has a rating more recent than most recent meeting time?
    const interval = (frequency == "Weekly" ? 7 : 14) * 24 * 60 * 60 * 1000;
    while (lastMeetingTime + interval < now) {
      lastMeetingTime = lastMeetingTime + interval;
    }
    const lastRateTime = with_mentor
      ? realmApp.app.currentUser?.customData.lastRatedMentorMeeting
      : realmApp.app.currentUser?.customData.lastRatedMenteeMeeting;

    if (
      lastRateTime &&
      Number(lastRateTime["$numberLong"]) >= lastMeetingTime
    ) {
      return;
    }

    // create the notification
    async function recordMeetingRating(rating) {
      const partner = with_mentor ? "mentor" : "mentee";
      const partner_id = with_mentor
        ? user.customData.mentor
        : user.customData.mentee;

      const ratedMeeting = {
        user_id: user.id,
        email: user.customData.name,
        partner: partner,
        partner_id: partner_id,
        meeting_time: lastMeetingTime,
        rating: rating,
      };

      try {
        await realmApp.appCurrentUser?.functions.recordMeetingRating(
          user.id,
          user.customData.name,
          partner,
          partner_id,
          lastMeetingTime,
          rating
        );
        await realmApp.appRefreshCustomData();
      } catch (err) {
        alert(
          "Something went wrong saving your rating. Please try again in a bit."
        );
        console.log(err);
      }
      window.location.reload();
    }

    let name = with_mentor
      ? team.get("mentor")?.username
      : team.get("mentee")?.username;
    name = name.split(" ");
    let first_name = name[0];
    let last_initial_padded =
      name.length > 0 ? " " + name[name.length - 1][0].toUpperCase() + "." : "";
    let lastMeetingDate = moment(lastMeetingTime).format("MMM Do");
    const header =
      `How was your meeting with ${first_name}${last_initial_padded} ` +
      `on ${lastMeetingDate}?`;
    const body = (
      <div>
        <div className="d-flex justify-content-center">
          <Button
            className="rating-button"
            onClick={() => recordMeetingRating(2)}
          >
            <LaughIcon />
            <p>Great!</p>
          </Button>
          <Button
            className="rating-button"
            onClick={() => recordMeetingRating(1)}
          >
            <NeutralIcon />
            <p>Okay</p>
          </Button>
          <Button
            className="rating-button"
            onClick={() => recordMeetingRating(0)}
          >
            <FrownIcon />
            <p>Poor</p>
          </Button>
        </div>
      </div>
    );

    onActivate();
    setNotif(
      <Notification
        header_text={header}
        body={body}
        button_text="We didn't meet"
        should_display_func={() => {
          true;
        }}
        button_action={() => recordMeetingRating(-1)}
      />
    );
  }

  useEffect(() => {
    maybeActivateNotification();
  }, []);

  return notif;
};

export const MeetYourMentorNotification = ({ onActivate = () => {} }) => {
  const header = `It's time to meet your ${getRelationTitle("mentor")}!`;
  const body = (
    <h6 className="mb-2">
      You've been assigned a new {getRelationTitle("mentor")}. Go to the team
      page to see their info.
    </h6>
  );
  const button = "See Their Info";

  const buttonAction = () => {
    navigate("/member/dashboard/team");
  };

  const realmApp = useRealmApp();

  const shouldDisplayFunc = () => {
    const hasMentor = Boolean(realmApp.app.currentUser?.customData.mentor);
    const hasViewedMentorInfo =
      realmApp.app.currentUser?.customData.hasViewedMentorInfo;
    return hasMentor && !hasViewedMentorInfo;
  };

  if (shouldDisplayFunc()) {
    onActivate();
    return (
      <Notification
        header_text={header}
        body={body}
        button_text={button}
        should_display_func={shouldDisplayFunc}
        button_action={buttonAction}
      />
    );
  } else {
    return <></>;
  }
};

export const NewMeetingTimeNotification = ({ onActivate = () => {} }) => {
  const realmApp = useRealmApp();
  const [notif, setNotif] = useState(<></>);

  async function maybeActivateNotification() {
    const mentor = realmApp.app.currentUser?.customData.mentor;
    if (!Boolean(mentor)) {
      return;
    }
    const docWithSchedule = await realmApp.appCurrentUser?.functions.getCheckInSchedule(
      mentor
    );
    if (!Boolean(docWithSchedule?.menteeMeetingTime?.first_timestamp)) {
      return;
    }
    if (realmApp.app.currentUser?.customData.hasViewedNewSchedule) {
      return;
    }
    // create the notification
    onActivate();

    const header = `Your ${getRelationTitle(
      "mentor"
    )} has updated your meeting schedule!`;
    const body = (
      <h6 className="mb-2">See the new schedule on the team page.</h6>
    );
    const button = "View New Schedule";
    const buttonAction = () => {
      navigate("/member/dashboard/team");
    };

    setNotif(
      <Notification
        header_text={header}
        body={body}
        button_text={button}
        should_display_func={() => true}
        button_action={buttonAction}
      />
    );
  }

  useEffect(() => {
    maybeActivateNotification();
  }, []);

  return notif;
};

export const ReadHandbooksNotification = ({ handbook_infos }) => {
  let realmApp = useRealmApp();
  let user = realmApp.app.currentUser?.customData;

  if (!Boolean(user)) {
    return <></>;
  }

  let index = 0;
  for (const [
    title,
    handbook,
    icon,
    setter,
    user_seen_attr,
  ] of handbook_infos) {
    index = index + 1;
    if (!Boolean(user[user_seen_attr])) {
      const header = `Learn how to use MentorAmp, Step ${index}`;
      const body = (
        <h6 className="mb-2">Check out the "{title}" handbook below.</h6>
      );
      const button = `Go`;
      async function buttonAction() {
        window.open(handbook.transcript_url, "_blank");
        await realmApp.maybeSetMongoUserBoolean(user_seen_attr);
      }

      return (
        <Notification
          header_text={header}
          body={body}
          button_text={button}
          should_display_func={() => true}
          button_action={buttonAction}
        />
      );
    }
  }

  return <></>;
};
