import React from "react";

export const LaughIcon = () => {
  return (
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 0.34375C8.33203 0.34375 0.09375 8.58203 0.09375 18.75C0.09375 28.918 8.33203 37.1562 18.5 37.1562C28.668 37.1562 36.9062 28.918 36.9062 18.75C36.9062 8.58203 28.668 0.34375 18.5 0.34375ZM29.7812 30.1055C28.2969 31.5898 26.5898 32.7773 24.7344 33.5195C22.7305 34.4102 20.6523 34.7812 18.4258 34.7812C16.2734 34.7812 14.1953 34.4102 12.1914 33.5195C10.3359 32.7773 8.62891 31.5898 7.14453 30.1055C5.66016 28.6211 4.47266 26.9141 3.65625 24.9844C2.83984 23.0547 2.39453 20.9766 2.39453 18.75C2.39453 16.5977 2.83984 14.5195 3.65625 12.5156C4.47266 10.6602 5.66016 8.87891 7.14453 7.46875C8.55469 5.98438 10.3359 4.79688 12.1914 3.98047C14.1953 3.16406 16.2734 2.71875 18.4258 2.71875C20.6523 2.71875 22.7305 3.16406 24.7344 3.98047C26.5898 4.79688 28.2969 5.98438 29.7812 7.46875C31.2656 8.87891 32.4531 10.6602 33.2695 12.5156C34.0859 14.5195 34.5312 16.5977 34.5312 18.75C34.5312 20.9766 34.0859 23.0547 33.2695 24.9844C32.4531 26.9141 31.2656 28.6211 29.7812 30.1055ZM21.3945 16.6719L22.0625 15.4102C22.6562 14.4453 23.4727 13.8516 24.4375 13.8516C25.3281 13.8516 26.1445 14.4453 26.7383 15.4102L27.4805 16.6719C27.7773 17.2656 28.5938 16.9688 28.5195 16.375C28.2969 13.2578 26.1445 11.0312 24.3633 11.0312C22.6562 11.0312 20.5039 13.2578 20.207 16.375C20.207 16.9688 21.0234 17.2656 21.3945 16.6719ZM9.51953 16.6719L10.1875 15.4102C10.7812 14.4453 11.5977 13.8516 12.5625 13.8516C13.4531 13.8516 14.2695 14.4453 14.8633 15.4102L15.6055 16.6719C15.9023 17.2656 16.7188 16.9688 16.6445 16.375C16.4219 13.2578 14.2695 11.0312 12.4883 11.0312C10.7812 11.0312 8.62891 13.2578 8.33203 16.375C8.33203 16.9688 9.14844 17.2656 9.51953 16.6719ZM28.5195 21.125H8.48047C7.73828 21.125 7.14453 21.793 7.29297 22.4609C7.88672 27.7305 12.4141 31.8125 17.9062 31.8125H19.0938C24.5117 31.8125 29.0391 27.7305 29.6328 22.4609C29.7812 21.793 29.1875 21.125 28.5195 21.125ZM19.0938 29.4375H17.9062C14.1211 29.4375 10.9297 26.9883 9.89062 23.5H27.0352C25.9961 26.9883 22.8047 29.4375 19.0938 29.4375Z"
        fill="#4E667D"
      />
    </svg>
  );
};
