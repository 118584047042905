import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Footer } from "../../../components/footer";
import DashboardNav from "../../../components/navbar";
import {
  RealmAppProvider,
  OnboardingRedirector,
  APP_ID,
  useRealmApp,
} from "../../../components/realm_app";
import "./index.css";
import {
  FileContractIcon,
  UserAstronautIcon,
  UserHardHatIcon,
  SunIcon,
} from "../../../components/icons";
import {
  HowWasMeetingNotification,
  MeetYourMentorNotification,
  SetUpMeetingTimeNotification,
  NewMeetingTimeNotification,
  ReadHandbooksNotification,
} from "../../../components/notification";
import { getRelationTitle } from "../../../utils";

const HandbookSection = (
  title,
  video_url,
  transcript_url,
  icon,
  on_click = () => {}
) => {
  async function click_handler() {
    window.open(transcript_url, "_blank");
    await on_click();
  }

  return (
    <>
      <div className="handbook-section">
        <div className="handbook-icon" onClick={click_handler}>
          {icon()}
        </div>
        <h6>{title}</h6>
      </div>
    </>
  );
};

const DiscordContactButton = ({ link }) => {
  async function click() {
    window.open(link, "_blank");
  }
  return (
    <Button className="purple-button" onClick={click}>
      Discord
    </Button>
  );
};

const DashboardPage = () => {
  const realmApp = useRealmApp();
  const [intro, setIntro] = React.useState(<>loading...</>);
  const [mentorHandbook, setMentorHandbook] = React.useState(<>loading...</>);
  const [menteeHandbook, setMenteeHandbook] = React.useState(<>loading...</>);
  const [commitments, setCommitments] = React.useState(<>loading...</>);
  const [handbookNotification, setHandbookNotification] = React.useState(<></>);
  const [groupContactSection, setGroupContactSection] = React.useState(<></>);

  async function recordSawVideos() {
    if (!realmApp.appCurrentUser?.customData.sawVideos) {
      await realmApp.setMongoUserAttribute({ sawVideos: true });
      console.log(
        `recorded user saw videos, which is now ${realmApp.app.currentUser?.customData.sawVideos}`
      );
    }
  }

  async function fetchResources() {
    let circle = await realmApp.fetchCircle();
    await fetchHandbookResources(circle);
    await fetchGroupContactLinks(circle);
  }

  async function fetchGroupContactLinks(circle) {
    if (circle) {
      let contact_buttons = [];
      let contacts = circle.program_manager;
      if (contacts.discord != null && contacts.discord != "") {
        contact_buttons.push(<DiscordContactButton link={contacts.discord} />);
      }

      if (contact_buttons.length > 0) {
        setGroupContactSection(
          <>
            <h2 className="mb-3">Group Contact Info</h2>
            <section className="group-contact-container">
              {contact_buttons}
            </section>
          </>
        );
      }
    }
  }

  async function fetchHandbookResources(circle) {
    if (circle) {
      let handbook_infos = [
        [
          "Introduction",
          circle["introduction"],
          SunIcon,
          setIntro,
          "hasViewedHandbook_1",
        ],
        [
          `${getRelationTitle("mentor", true)} Guide`,
          circle["mentor_handbook"],
          UserHardHatIcon,
          setMentorHandbook,
          "hasViewedHandbook_2",
        ],
        [
          `${getRelationTitle("mentee", true)} Guide`,
          circle["mentee_handbook"],
          UserAstronautIcon,
          setMenteeHandbook,
          "hasViewedHandbook_3",
        ],
        [
          "Goals",
          circle["commitments"],
          FileContractIcon,
          setCommitments,
          "hasViewedHandbook_4",
        ],
      ];
      for (let [
        title,
        handbook,
        icon,
        setter,
        user_seen_attr,
      ] of handbook_infos) {
        async function onclick() {
          let did_set = await realmApp.maybeSetMongoUserBoolean(user_seen_attr);
          if (did_set) {
            // refresh the page to update the handbook notification
            window.location.reload();
          }
        }

        setter(
          HandbookSection(
            title,
            handbook["video_url"],
            handbook["transcript_url"],
            icon,
            onclick
          )
        );
      }
      setHandbookNotification(
        <ReadHandbooksNotification handbook_infos={handbook_infos} />
      );
    }
  }

  useEffect(() => {
    recordSawVideos();
    fetchResources();
  }, []);

  const name = realmApp.appCurrentUser?.customData?.username;
  const welcomeString = name ? `Welcome, ${name}!` : "Welcome";

  return (
    <>
      <div className="page">
        <DashboardNav />
        <br />
        <br />
        <section className="page-content">
          <h2 className="mb-3">{welcomeString}</h2>
          <section className="notifications">
            {handbookNotification}
            <SetUpMeetingTimeNotification />
            <MeetYourMentorNotification />
            <NewMeetingTimeNotification />
            <HowWasMeetingNotification with_mentor={false} />
            <HowWasMeetingNotification with_mentor={true} />
          </section>

          <h2 className="mb-0 mt-5">Handbook</h2>
          <h6 className="mb-3">
            Learn how to get the most out of your program.
          </h6>
          <section className="handbook-container">
            <div className="handbook-section-container">{intro}</div>
            <div className="handbook-section-container">{mentorHandbook}</div>
            <div className="handbook-section-container">{menteeHandbook}</div>
            <div className="handbook-section-container">{commitments}</div>
          </section>
          {groupContactSection}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <DashboardPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
