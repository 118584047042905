import React from "react";

export const FileContractIcon = () => {
  return (
    <svg
      viewBox="0 0 34 45"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6997 31.7812L16.4966 28.1719C16.2387 27.3125 15.4653 26.7969 14.6059 26.7969C13.6606 26.7969 12.8872 27.3125 12.6294 28.1719L11.0825 32.8984C10.9106 33.2422 10.5669 33.5 10.1372 33.5H9.10593C8.3325 33.5 7.73093 34.1875 7.73093 34.875C7.73093 35.6484 8.3325 36.25 9.10593 36.25H10.1372C11.77 36.25 13.145 35.3047 13.6606 33.7578L14.6059 31.0078L15.9809 35.3906C16.1528 35.8203 16.6684 36.25 17.1841 36.25C17.27 36.25 17.27 36.25 17.27 36.25C17.8716 36.25 18.3012 35.9922 18.5591 35.5625L19.1606 34.1875C19.4184 33.7578 19.8481 33.6719 20.02 33.6719C20.2778 33.6719 20.7075 33.7578 20.9653 34.2734C21.5669 35.4766 22.8559 36.25 24.2309 36.25H25.6059C26.2934 36.25 26.9809 35.6484 26.9809 34.875C26.9809 34.1875 26.2934 33.5 25.6059 33.5H24.2309C23.8872 33.5 23.5434 33.3281 23.3716 32.9844C22.3403 30.9219 19.4184 30.4062 17.6997 31.7812ZM7.04343 8.75H16.6684C17.0122 8.75 17.3559 8.49219 17.3559 8.0625V6.6875C17.3559 6.34375 17.0122 6 16.6684 6H7.04343C6.61375 6 6.35593 6.34375 6.35593 6.6875V8.0625C6.35593 8.49219 6.61375 8.75 7.04343 8.75ZM17.3559 13.5625V12.1875C17.3559 11.8438 17.0122 11.5 16.6684 11.5H7.04343C6.61375 11.5 6.35593 11.8438 6.35593 12.1875V13.5625C6.35593 13.9922 6.61375 14.25 7.04343 14.25H16.6684C17.0122 14.25 17.3559 13.9922 17.3559 13.5625ZM32.5669 8.92188L25.4341 1.78906C24.6606 1.01562 23.5434 0.5 22.5122 0.5H4.895C2.66062 0.585938 0.855934 2.39062 0.855934 4.71094V40.4609C0.855934 42.6953 2.66062 44.5 4.895 44.5H29.7309C31.9653 44.5 33.8559 42.6953 33.8559 40.4609V11.8438C33.8559 10.8125 33.3403 9.69531 32.5669 8.92188ZM22.8559 3.33594C23.0278 3.42188 23.2856 3.50781 23.4575 3.67969L30.6762 10.8984C30.8481 11.0703 30.9341 11.3281 31.02 11.5859H22.8559V3.33594ZM31.1059 40.4609C31.1059 41.1484 30.4184 41.8359 29.7309 41.8359H4.895C4.2075 41.8359 3.52 41.1484 3.52 40.4609V4.71094C3.52 3.9375 4.2075 3.25 4.895 3.25H20.1059V12.2734C20.1059 13.3906 20.9653 14.25 22.1684 14.25H31.1059V40.4609Z"
        fill="#4E667D"
      />
    </svg>
  );
};
