import React from "react";

export const UserHardHatIcon = () => {
  return (
    <svg
      viewBox="0 0 39 45"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0044 30.75C24.5122 30.75 23.395 32.125 19.3559 32.125C15.2309 32.125 14.1137 30.75 11.6216 30.75C5.26218 30.75 0.105934 35.9922 0.105934 42.3516C0.105934 43.5547 1.05125 44.5 2.25437 44.5H36.3716C37.5747 44.5 38.6059 43.5547 38.6059 42.3516C38.6059 35.9922 33.3637 30.75 27.0044 30.75ZM2.85593 41.75C3.11375 37.2812 7.06687 33.5859 11.6216 33.5C13.3403 33.5 14.9731 34.875 19.3559 34.875C23.7387 34.875 25.2856 33.5 27.0044 33.5C31.5591 33.5859 35.5122 37.2812 35.77 41.75H2.85593ZM7.66843 14.25H8.69968C8.52781 15.0234 8.35593 16.2266 8.35593 17C8.35593 23.1016 13.2544 28 19.3559 28C25.3716 28 30.3559 23.1016 30.3559 17C30.27 16.2266 30.0981 15.0234 29.9262 14.25H31.0434C31.3872 14.25 31.7309 13.9922 31.7309 13.5625V12.1875C31.7309 11.8438 31.3872 11.5 31.0434 11.5H30.3559C30.3559 7.11719 27.3481 3.42188 23.2231 2.30469C22.8794 1.35938 21.7622 0.585938 20.7309 0.5H17.9809C16.8637 0.585938 15.7466 1.35938 15.4028 2.30469C11.3637 3.42188 8.35593 7.11719 8.35593 11.5H7.66843C7.23875 11.5 6.98093 11.8438 6.98093 12.1875V13.5625C6.98093 13.9922 7.23875 14.25 7.66843 14.25ZM23.4809 5.22656C25.7153 6.25781 27.52 9.09375 27.6059 11.5H23.4809V5.22656ZM17.9809 3.25H20.7309V11.5H17.9809V3.25ZM11.1059 11.5C11.1059 9.09375 12.9106 6.25781 15.2309 5.22656V11.5H11.1059ZM11.6216 14.25H27.0903C27.3481 15.0234 27.52 16.2266 27.6059 17C27.6059 21.5547 23.8247 25.25 19.3559 25.25C14.8012 25.25 11.1059 21.5547 11.1059 17C11.1059 16.2266 11.2778 15.0234 11.6216 14.25Z"
        fill="#4E667D"
      />
    </svg>
  );
};
