import React from "react";

export const EditIcon = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5625 10.7812C12.5 10.8438 12.5 10.9062 12.5 10.9688V14.5H1.5V3.5H9.03125C9.09375 3.5 9.15625 3.5 9.21875 3.4375L10.2188 2.4375C10.375 2.28125 10.25 2 10.0312 2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V9.96875C14 9.75 13.7188 9.625 13.5625 9.78125L12.5625 10.7812ZM17.4375 4.5C18.1562 3.78125 18.1562 2.625 17.4375 1.90625L16.0938 0.5625C15.375 -0.15625 14.2188 -0.15625 13.5 0.5625L5.3125 8.75L5 11.5938C4.90625 12.4062 5.59375 13.0938 6.40625 13L9.25 12.6875L17.4375 4.5ZM14.375 5.4375L8.5625 11.25L6.5 11.5L6.75 9.4375L12.5625 3.625L14.375 5.4375ZM16.375 2.96875C16.5312 3.09375 16.5312 3.28125 16.4062 3.4375L15.4375 4.40625L13.625 2.5625L14.5625 1.625C14.6875 1.46875 14.9062 1.46875 15.0312 1.625L16.375 2.96875Z"
        fill="#00BADB"
      />
    </svg>
  );
};
