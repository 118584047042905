import React from "react";

export const FrownIcon = () => {
  return (
    <svg
      width="37"
      height="38"
      viewBox="0 0 37 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 0.34375C8.33203 0.34375 0.09375 8.58203 0.09375 18.75C0.09375 28.918 8.33203 37.1562 18.5 37.1562C28.668 37.1562 36.9062 28.918 36.9062 18.75C36.9062 8.58203 28.668 0.34375 18.5 0.34375ZM18.5 34.7812C9.59375 34.7812 2.46875 27.6562 2.46875 18.75C2.46875 9.91797 9.59375 2.71875 18.5 2.71875C27.332 2.71875 34.5312 9.91797 34.5312 18.75C34.5312 27.6562 27.332 34.7812 18.5 34.7812ZM12.5625 17.5625C13.8242 17.5625 14.9375 16.5234 14.9375 15.1875C14.9375 13.9258 13.8242 12.8125 12.5625 12.8125C11.2266 12.8125 10.1875 13.9258 10.1875 15.1875C10.1875 16.5234 11.2266 17.5625 12.5625 17.5625ZM24.4375 12.8125C23.1016 12.8125 22.0625 13.9258 22.0625 15.1875C22.0625 16.5234 23.1016 17.5625 24.4375 17.5625C25.6992 17.5625 26.8125 16.5234 26.8125 15.1875C26.8125 13.9258 25.6992 12.8125 24.4375 12.8125ZM18.5 21.125C15.5312 21.125 10.8555 22.9062 10.1875 26.6914C10.0391 27.5078 10.3359 28.3242 10.9297 28.8438C11.5977 29.4375 12.4141 29.5859 13.2305 29.3633C15.3828 28.6953 17.3867 28.25 18.5 28.25C19.5391 28.25 21.543 28.6953 23.6953 29.3633C24.2891 29.5117 25.1797 29.5859 25.9961 28.8438C26.5898 28.3242 26.8867 27.5078 26.7383 26.6914C26.0703 22.9062 21.3945 21.125 18.5 21.125ZM18.5 25.875C17.1641 25.875 15.0117 26.3203 12.5625 27.0625C12.9336 24.9102 16.2734 23.5 18.5 23.5C20.6523 23.5 23.9922 24.9102 24.4375 27.0625C21.9141 26.3203 19.7617 25.875 18.5 25.875Z"
        fill="#4E667D"
      />
    </svg>
  );
};
