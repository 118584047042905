import React from "react";

export const SunIcon = () => {
  return (
    <svg
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 12.875C17.1719 12.875 12.875 17.1719 12.875 22.5C12.875 27.8281 17.1719 32.125 22.5 32.125C27.7422 32.125 32.125 27.8281 32.125 22.5C32.125 17.1719 27.7422 12.875 22.5 12.875ZM22.5 29.375C18.6328 29.375 15.625 26.2812 15.625 22.5C15.625 18.7188 18.6328 15.625 22.5 15.625C26.2812 15.625 29.375 18.7188 29.375 22.5C29.375 26.2812 26.2812 29.375 22.5 29.375ZM44.5 22.5C44.5 22.0703 44.2422 21.6406 43.8125 21.3828L36.7656 16.5703L38.3984 8.23438C38.4844 7.71875 38.3125 7.28906 38.0547 6.94531C37.7109 6.60156 37.1953 6.51562 36.7656 6.60156L28.3438 8.23438L23.6172 1.10156C23.1016 0.328125 21.8125 0.328125 21.2969 1.10156L16.5703 8.23438L8.14844 6.60156C7.71875 6.51562 7.20312 6.60156 6.94531 6.94531C6.60156 7.28906 6.42969 7.71875 6.51562 8.14844L8.23438 16.5703L1.10156 21.3828C0.671875 21.6406 0.5 22.0703 0.5 22.5C0.5 23.0156 0.671875 23.4453 1.10156 23.7031L8.23438 28.4297L6.51562 36.8516C6.42969 37.2812 6.60156 37.7969 6.94531 38.0547C7.20312 38.3984 7.71875 38.5703 8.14844 38.4844L16.5703 36.7656L21.2969 43.8984C21.5547 44.3281 21.9844 44.5 22.5 44.5C22.9297 44.5 23.3594 44.3281 23.6172 43.8984L28.3438 36.7656L36.7656 38.4844C37.2812 38.5703 37.7109 38.3984 38.0547 38.0547C38.3125 37.7969 38.4844 37.2812 38.3984 36.8516L36.7656 28.4297L43.8125 23.7031C44.2422 23.4453 44.5 23.0156 44.5 22.5ZM34.4453 26.625C33.9297 26.9688 33.7578 27.4844 33.8438 28.0859L35.3047 35.3906L28 33.9297C27.3984 33.7578 26.8828 34.0156 26.625 34.5312L22.5 40.7188L18.2891 34.5312C18.0312 34.1016 17.6016 33.9297 17.1719 33.9297C17.0859 33.9297 17 33.9297 16.9141 33.9297L9.60938 35.3906L11.0703 28.0859C11.1562 27.5703 10.9844 26.9688 10.4688 26.625L4.28125 22.5L10.4688 18.375C10.9844 18.1172 11.1562 17.5156 11.0703 17L9.60938 9.69531L16.9141 11.1562C17.5156 11.2422 18.0312 10.9844 18.2891 10.5547L22.5 4.36719L26.625 10.5547C26.8828 10.9844 27.4844 11.2422 28 11.1562L35.3047 9.69531L33.8438 17C33.7578 17.5156 33.9297 18.1172 34.4453 18.375L40.6328 22.5L34.4453 26.625Z"
        fill="#4E667D"
      />
    </svg>
  );
};
